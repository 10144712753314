<template>
  <div class="page">
    <div class="banner">
      <img :src="detail.picture" alt="" />
    </div>
    <div class="title">作品名称：{{ detail.worksName }}</div>
    <div class="content" v-html="detail.worksIntroduce"></div>
    <div class="info">
      <!-- <div class="time">{{ detail.creationTime }}</div> -->
      <img src="./img/look-icon.png" alt="" />
      <div class="num">{{ detail.readCount || 0 }}</div>
    </div>
  </div>
</template>

<script>
import { stringify } from "querystring";
import { goodWorksDetails, addReadCount } from "./api.js";
export default {
  data() {
    return {
      workId: null,
      detail: {},
    };
  },
  created() {
    const { workId } = this.$route.query;
    this.workId = workId;
    this.addWatcher(workId);
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    addWatcher(id) {
      let params = {
        workId: id,
      };
      this.$axios
        .post(`${addReadCount}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
          }
        });
    },
    getDetail() {
      let params = {
        workId: this.workId,
      };
      this.$axios.get(`${goodWorksDetails}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.detail = res.data;
          this.detail.picture = this.$handleImg(750, 340, this.detail.picture);
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fafafa;
  padding-bottom: 90px;
  .info {
    width: 100%;
    position: fixed;
    bottom: 60px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #aaaaaa;
    padding: 0 46px;
    box-sizing: border-box;
    justify-content: flex-end;
    .time {
      flex: 1;
    }
    img {
      margin-right: 8px;
      width: 32px;
    }
  }
  .content {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 0 44px;
    line-height: 44px;
    /deep/ video {
      width: 100%;
    }
    /deep/ img {
      width: 100%;
    }
  }
  .title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    padding: 54px 44px 60px;
  }
  .banner {
    width: 100%;
    height: 340px;
    img {
      display: block;
      margin: 0 auto;
      height: 100%;
    }
  }
}
</style>